import { addDays, endOfMonth, endOfWeek, format, isBefore, parse, parseISO, startOfMonth, startOfWeek } from "date-fns";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { FTLEvent, Week } from "../types";
import DataContext from "./data";

const CalendarContext = React.createContext<{
  weeks: Week[],
  getWeeks: (date: Date) => void,
  selectedWeek?: Week,
  selectedEvent?: FTLEvent,
  displayedMonthDate: Date,
  setDisplayedMonthDate: (date: Date) => void,
  setSelectedEvent: (event: FTLEvent) => void,
}>({
  weeks: [],
  getWeeks: (date: Date) => {},
  displayedMonthDate: new Date(),
  setDisplayedMonthDate: (date: Date) => {},
  setSelectedEvent: (event: FTLEvent) => {},
});

export function CalendarProvider(props: { children: React.ReactNode }) {
  const { events } = useContext(DataContext)
  const [weeks, setWeeks] = useState<Week[]>([]);
  const [displayedMonthDate, setDisplayedMonthDate] = useState<Date>(new Date());
  const [selectedEvent, setSelectedEvent] = useState<FTLEvent | undefined>(undefined)

  const getWeeks = (date: Date): Week[] => {
    const firstDay = startOfWeek(startOfMonth(date))
    const lastDay = endOfWeek(endOfMonth(date))

    let currentDate = firstDay;
    let weeks: Week[] = []
    let currentRow = 0  
    while (isBefore(currentDate, lastDay)) {
      weeks.push([])
      for (var i = 0; i < 7; i++) {
        const dayEvents = events?.filter((e: FTLEvent) => {
          const date = parse(e.date.split('T')[0], 'yyyy-MM-dd', new Date())
          return format(date, 'MdY') === format(currentDate, 'MdY')
        })
        weeks[currentRow].push({date: currentDate, events: dayEvents?.length ? dayEvents : undefined })
        currentDate = addDays(currentDate, 1);
      }
      currentRow++
    }

    return weeks
  };

  useEffect(() => {
    setWeeks(getWeeks(displayedMonthDate));
  }, [events, displayedMonthDate]);

  const selectedWeek = useMemo((): Week => {
    const selectedWeek = weeks.find((week: Week) => {
      return !!week.find((day) => format(displayedMonthDate, 'MdY') === format(day.date, 'MdY'))
    })
    return selectedWeek || []
  }, [weeks, events, displayedMonthDate]);

  useEffect(() => {
    if (selectedEvent && events) {
      const event = events.find(e => e.id === selectedEvent.id)
      setSelectedEvent(event)
    }
  }, [selectedEvent, events])

  return (
    <CalendarContext.Provider value={{
      getWeeks,
      weeks,
      selectedWeek,
      displayedMonthDate,
      setDisplayedMonthDate,
      selectedEvent,
      setSelectedEvent,
    }}>
      {props.children}
    </CalendarContext.Provider>
  )
}

export default CalendarContext;