import { addDays, endOfMonth, endOfWeek, format, isBefore, parseISO, startOfMonth, startOfWeek } from "date-fns";
import { useSession } from "next-auth/react";
import React, { useState, useCallback, useEffect, useMemo, useContext } from "react";
import { FTLEvent, FTLLocation, Week } from "../types";
import RulesContext from "./rules";

const DataContext = React.createContext<{
  events: FTLEvent[],
  allEvents: FTLEvent[],
  getLocations: (market?: string) => Promise<FTLLocation[]>,
  locations: FTLLocation[],
  allLocations: FTLLocation[],
  getEvents: (market?: string) => Promise<FTLEvent[]>,
  getEvent: (eventId: string) => Promise<FTLEvent>,
  bookEvent: (event: FTLEvent) => Promise<void>,
  unbookEvent: (event: FTLEvent) => Promise<void>,
  sendTextInvite: (event: FTLEvent) => Promise<void>,
  excludedLocations: string[],
  excludeLocation: (locationId: string) => void,
  includeLocation: (locationId: string) => void,
  setExcludedLocations: (excludedLocations: string[]) => void,
  filterByType: (type: 'lunch' | 'dinner') => void,
  setShowMyEventsOnly: (show: boolean) => void,
}>({
  events: [],
  allEvents: [],
  locations: [],
  allLocations: [],
  getLocations: (market?: string) => { return Promise.resolve([]) },
  getEvents: (market?: string) => { return Promise.resolve([]) },
  getEvent: (eventId: string) => { return Promise.resolve({} as FTLEvent) },
  bookEvent: (event: FTLEvent) => { return Promise.resolve() },
  unbookEvent: (event: FTLEvent) => { return Promise.resolve() },
  sendTextInvite: (event: FTLEvent) => { return Promise.resolve() },
  excludedLocations: [],
  excludeLocation: (locationId: string) => {},
  includeLocation: (locationId: string) => {},
  setExcludedLocations: (excludedLocations: string[]) => {},
  filterByType: (type: 'lunch' | 'dinner') => {},
  setShowMyEventsOnly: (show: boolean) => {},
});

export function DataProvider(props: { children: React.ReactNode }) {
  const session = useSession()
  const rulesContext = useContext(RulesContext);

  const [excludedLocations, setExcludedLocations] = useState<string[]>([]);
  const [allLocations, setAllLocations] = useState<FTLLocation[]>([]);
  const [events, setEvents] = useState<FTLEvent[]>([]);
  const [showMyEventsOnly, setShowMyEventsOnly] = useState<boolean>(false);

  const truckId: string = useMemo(() => {
    return session?.data?.user?.truck || '';
  }, [session]);

  const getLocations = (market?: string) => {
    return new Promise<FTLLocation[]>(async (resolve, reject) => {
      const response = await fetch(`/api/locations${market ? `?market=${market}` : ''}`);
      const data = await response.json();
      if (data?.locations) {
        setAllLocations(data.locations);
      }
      resolve(data?.locations);
    })
  };

  const getEvents = (market?: string) => {
    return new Promise<FTLEvent[]>(async (resolve, reject) => {
      const response = await fetch(`/api/events${market ? `?market=${market}` : ''}`);
      const data = await response.json();
      if (data?.events) {
        setEvents(data.events);
      }
      resolve(data.events);
    })
  };

  const getEvent = (eventId: string) => {
    return new Promise<FTLEvent>(async (resolve, reject) => {
      const response = await fetch(`/api/event/${eventId}`);
      const data = await response.json();
      if (data?.event) {
        resolve(data.event)
      }
      reject();
    })
  };

  const bookEvent = async (event: FTLEvent) => {
    return new Promise<void>(async (resolve, reject) => {
      const response = await fetch('/api/book-event', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event,
        })
      })
      const data = await response.json();
      
      if (data.success) {
        await getEvents();
      }

      resolve()
    })
  };

  const unbookEvent = async (event: FTLEvent) => {
    return new Promise<void>(async (resolve, reject) => {
      const response = await fetch('/api/unbook-event', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event,
        })
      })
      const data = await response.json();
      
      if (data.success) {
        await getEvents();
      }

      resolve()
    })
  };

  const sendTextInvite = async (event: FTLEvent) => {
    return new Promise<void>(async (resolve, reject) => {
      const response = await fetch('/api/send-text-invite', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          event,
        })
      })
      const data = await response.json();
      
      if (data.success) {
        await getEvents();
      }

      resolve()
    })
  }

  const locations: FTLLocation[] = useMemo(() => {
    if (!!rulesContext?.truckCountRules && !!events) {
      const locationIdsWithRules = allLocations.filter((location) => {
        return !!rulesContext.truckCountRules[`${location.id}-${truckId}`];
      }).map((location) => {
        return location.id;
      })

      const locationIdsWithBookedEvents = events.filter((event) => {
        return event.scheduledTrucks.includes(truckId)
      }).map((event) => {
        return event.location;
      })

      const uniqueLocationIds = [...new Set([...locationIdsWithRules, ...locationIdsWithBookedEvents])];

      return allLocations.filter((location) => {
        return uniqueLocationIds.includes(location.id);
      });
    }
    return allLocations;
  }, [allLocations, rulesContext?.truckCountRules, events, truckId]);

  const filteredLocations = useMemo(() => {
    return locations.filter((location) => {
      return !excludedLocations.includes(location.id);
    })
  }, [locations, excludedLocations]);

  const filteredEvents = useMemo(() => {
    const newEvents = events.filter((event) => {
      return filteredLocations.map((location) => location.id).includes(event.location);
    })
    if (showMyEventsOnly) {
      return newEvents.filter((event) => {
        return event.scheduledTrucks.includes(truckId);
      })
    }
    return newEvents
  }, [events, filteredLocations, showMyEventsOnly]);

  const excludeLocation = useCallback((locationId: string) => {
    setExcludedLocations((excludedLocations) => {
      return [...excludedLocations, locationId];
    })
  }, [excludedLocations, setExcludedLocations]);

  const includeLocation = useCallback((locationId: string) => {
    setExcludedLocations((excludedLocations) => {
      return excludedLocations.filter((excludedLocation) => {
        return excludedLocation !== locationId;
      })
    })
  }, [excludeLocation, setExcludedLocations]);

  const filterByType = useCallback((type: 'lunch' | 'dinner') => {
    setExcludedLocations(locations.filter((location) => location.type !== type).map((location) => location.id))
  }, [locations, excludedLocations])

  return (
    <DataContext.Provider value={{
      getLocations,
      locations,
      allLocations,
      getEvents,
      getEvent,
      events: filteredEvents,
      allEvents: events,
      bookEvent,
      unbookEvent,
      sendTextInvite,
      excludedLocations,
      excludeLocation,
      includeLocation,
      setExcludedLocations,
      filterByType,
      setShowMyEventsOnly,
    }}>
      {props.children}
    </DataContext.Provider>
  )
}

export default DataContext;