import { format, isAfter, isBefore, isWithinInterval, parse } from "date-fns";
import React, { useState, useEffect, useContext, useMemo, useCallback } from "react";
import { FTLCategoryBufferRule, FTLCategoryCountRule, FTLEvent, FTLLocation, FTLTruck, FTLTruckBufferRule, FTLTruckCountRule, Week } from "../types";
import CategoriesContext from "./categories";
import TrucksContext from "./trucks";

const RulesContext = React.createContext<{
  categoryCountRules: {[key: string]: FTLCategoryCountRule},
  categoryCountRulesLocationOnly: {[key: string]: FTLCategoryCountRule[]},
  categoryBufferRules: {[key: string]: FTLCategoryBufferRule},
  truckCountRules: {[key: string]: FTLTruckCountRule},
  truckBufferRules: {[key: string]: FTLTruckBufferRule},
  getRules: (market?: string) => Promise<void>,
  getCategoryCountRuling: (event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => {description: string, ok: boolean},
  getCategoryBufferRuling: (event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => {description: string, ok: boolean},
  getTruckCountRuling: (event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => {description: string, ok: boolean},
  getTruckBufferRuling: (event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => {description: string, ok: boolean},
  getLocationCountRuling: (event?: FTLEvent, location?: FTLLocation) => {description: string, ok: boolean},
}>({
  categoryCountRules: {},
  categoryCountRulesLocationOnly: {},
  categoryBufferRules: {},
  truckCountRules: {},
  truckBufferRules: {},
  getRules: (market?: string) => {return Promise.resolve() },
  getCategoryCountRuling: (event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => ({description: '', ok: true}),
  getCategoryBufferRuling: (event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => ({description: '', ok: true}),
  getTruckCountRuling: (event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => ({description: '', ok: true}),
  getTruckBufferRuling: (event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => ({description: '', ok: true}),
  getLocationCountRuling: (event?: FTLEvent, location?: FTLLocation) => ({description: '', ok: true}),
});

export function RulesProvider(props: { children: React.ReactNode }) {
  const trucksContext = useContext(TrucksContext);
  const categoriesContext = useContext(CategoriesContext);

  const [categoryCountRules, setCategoryCountRules] = useState<{[key: string]: FTLCategoryCountRule}>({});
  const [categoryCountRulesLocationOnly, setCategoryCountRulesLocationOnly] = useState<{[key: string]: FTLCategoryCountRule[]}>({});
  const [categoryBufferRules, setCategoryBufferRules] = useState<{[key: string]: FTLCategoryBufferRule}>({});
  const [truckCountRules, setTruckCountRules] = useState<{[key: string]: FTLTruckCountRule}>({});
  const [truckBufferRules, setTruckBufferRules] = useState<{[key: string]: FTLTruckBufferRule}>({});

  const getRules = (market?: string) => {
    return new Promise<void>(async (resolve, reject) => {
      const response = await fetch(`/api/rules?market=${market}`);
      const data = await response.json();
      if (data?.categoryCountRules) {
        const categoryCountRules = data.categoryCountRules.reduce((obj: {[key: string]: FTLCategoryCountRule}, rule: FTLCategoryCountRule) => {
          rule.locations.forEach((location) => {
            obj[`${location}-${rule.category}`] = rule;
          })
          return obj;
        }, {});
        setCategoryCountRules(categoryCountRules);

        const categoryCountRulesForLocation = data.categoryCountRules.reduce((obj: {[key: string]: FTLCategoryCountRule[]}, rule: FTLCategoryCountRule) => {
          rule.locations.forEach((location) => {
            if (!obj[`${location}`]) {
              obj[`${location}`] = [rule];
            } else {
              obj[`${location}`].push(rule);
            }
          })
          return obj;
        }, {});
        setCategoryCountRulesLocationOnly(categoryCountRulesForLocation);
      }

      if (data?.categoryBufferRules) {
        const categoryBufferRules = data.categoryBufferRules.reduce((obj: {[key: string]: FTLCategoryBufferRule}, rule: FTLCategoryBufferRule) => {
          rule.locations.forEach((location) => {
            obj[`${location}-${rule.category}`] = rule;
          });
          return obj;
        }, {});
        setCategoryBufferRules(categoryBufferRules);
      }

      if (data?.truckCountRules) {
        const truckCountRules = data.truckCountRules.reduce((obj: {[key: string]: FTLTruckCountRule}, rule: FTLTruckCountRule) => {
          obj[`${rule.location}-${rule.truck}`] = rule;
          return obj;
        }, {});
        setTruckCountRules(truckCountRules);
      }

      if (data?.truckBufferRules) {
        const truckBufferRules = data.truckBufferRules.reduce((obj: {[key: string]: FTLTruckBufferRule}, rule: FTLTruckBufferRule) => {
          rule.locations.forEach((location) => {
            obj[`${location}-${rule.truck}`] = rule;
          })
          return obj;
        }, {});
        setTruckBufferRules(truckBufferRules);
      }

      resolve();
    });
  }

  const getCategoryCountRuling = useCallback((event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => {
    // New strict category count rules
    if (!event || !truck || !events) {
      return { description: '', ok: true };
    }
    const rules: FTLCategoryCountRule[] = categoryCountRulesLocationOnly[`${event.location}`];
    if (!rules) {
      return { description: '', ok: true };
    }
    const categoryName = categoriesContext.categories[truck.category]?.title;
    if (!categoryName) {
      return { description: "Truck hasn't been assigned a category", ok: false };
    }

    const matchesCategory = rules.some((rule) => rule.category === truck.category)
    if (!matchesCategory) {
      return {description: `${categoryName} trucks not allowed`, ok: false};
    }

    const rule = categoryCountRules[`${event.location}-${truck.category}`];
    const total = rule.count;
    const booked = event.scheduledTrucks
      .filter((t) => !!trucksContext.trucks[t]?.category)
      .filter(
        (t) => trucksContext.trucks[t].category === truck.category
      ).length;

    return {description: `${booked} booking(s) out of ${total} for ${categoryName} trucks`, ok: total > booked};
  }, [categoryCountRules, categoryCountRulesLocationOnly, trucksContext])

  const getCategoryBufferRuling = useCallback((event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => {
    if (!event || !truck || !events) {
      return { description: '', ok: true };
    }
    const rule = categoryBufferRules[`${event.location}-${truck.category}`];
    if (!rule) {
      return { description: '', ok: true };
    }

    const locationEvents = events.filter((e) => e.location === event.location);

    // Check the buffer number of events in the past
    const indexOfEvent = locationEvents.findIndex((e) => e.id === event.id);
    const pastEvents = locationEvents.slice(0, indexOfEvent);
    let pastBufferOk = true;
    for(let i = pastEvents.length - 1; i >= pastEvents.length - rule.buffer; i--) {
      if (pastEvents[i]?.scheduledTrucks?.some((t) => {
        const newT = trucksContext.trucks[t];
        return trucksContext.trucks[t]?.category === truck.category
      })) {
        pastBufferOk = false;
        break;
      }
    }

    const futureEvents = locationEvents.slice(indexOfEvent + 1);
    let futureBufferOk = true;
    for(let i = 0; i < rule.buffer; i++) {
      if (futureEvents[i]?.scheduledTrucks?.some((t) => {
        return trucksContext.trucks[t]?.category === truck.category
      })) {
        futureBufferOk = false;
        break;
      }
    }

    const categoryName = categoriesContext.categories[truck.category].title;

    return {description: `${categoryName} bookings must be more than ${rule.buffer} event(s) apart`, ok: pastBufferOk && futureBufferOk};
  }, [categoryBufferRules, trucksContext])

  const getTruckCountRuling = useCallback((event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => {
    if (!event || !truck || !events) {
      return { description: '', ok: true };
    }
    const rule = truckCountRules[`${event.location}-${truck.id}`];
    if (!rule) {
      return { description: `${truck.title} bookings not currently available for ${event.title}`, ok: false };
    }

    const eventDate = parse(event.date.split('T')[0], 'yyyy-MM-dd', new Date());
    const ruleStartDate = rule.startDate ? parse(rule.startDate.split('T')[0], 'yyyy-MM-dd', new Date()) : null;
    const ruleEndDate = rule.endDate ? parse(rule.endDate.split('T')[0], 'yyyy-MM-dd', new Date()) : null;
    if (ruleStartDate && ruleEndDate) {
      if (isBefore(eventDate, ruleStartDate) || isAfter(eventDate, ruleEndDate)) {
        return { description: `${truck.title} bookings only allowed between ${format(ruleStartDate, 'MMM do yyyy')} - ${format(ruleEndDate, 'MMM do yyyy')}`, ok: false };
      }
    }

    const total = rule.count;
    const booked = events
      .filter((e) => e.location === event.location)
      .filter((e) => {
        if (!ruleStartDate || !ruleEndDate) {
          return true;
        }
        const eDate = parse(e.date.split('T')[0], 'yyyy-MM-dd', new Date());
        return isWithinInterval(eDate, {start: ruleStartDate, end: ruleEndDate});
      })
      .filter((e) => e.scheduledTrucks.some((t) => t === truck.id)).length;

    if (ruleStartDate && ruleEndDate) {
      return {description: `${booked} booking(s) out of ${total} for ${truck.title} for ${format(ruleStartDate, 'MMM do yyyy')} - ${format(ruleEndDate, 'MMM do yyyy')}`, ok: total > booked};
    } else {
      return {description: `${event.title} events not available for ${truck.title}`, ok: false};
    }
  }, [truckCountRules])

  const getTruckBufferRuling = useCallback((event?: FTLEvent, truck?: FTLTruck, events?: FTLEvent[]) => {
    if (!event || !truck || !events) {
      return { description: '', ok: true };
    }
    const rule = truckBufferRules[`${event.location}-${truck.id}`];
    if (!rule) {
      return { description: '', ok: true };
    }

    const locationEvents = events.filter((e) => e.location === event.location);

    // Check the buffer number of events in the past
    const indexOfEvent = locationEvents.findIndex((e) => e.id === event.id);
    const pastEvents = locationEvents.slice(0, indexOfEvent);
    let pastBufferOk = true;
    for(let i = pastEvents.length - 1; i >= pastEvents.length - rule.buffer; i--) {
      if (pastEvents[i]?.scheduledTrucks?.some((t) => t === truck.id)) {
        pastBufferOk = false;
        break;
      }
    }

    const futureEvents = locationEvents.slice(indexOfEvent + 1);
    let futureBufferOk = true;
    for(let i = 0; i < rule.buffer; i++) {
      if (futureEvents[i]?.scheduledTrucks?.some((t) => t === truck.id)) {
        futureBufferOk = false;
        break;
      }
    }

    return {description: `${truck.title} bookings must be more than ${rule.buffer} event(s) apart`, ok: pastBufferOk && futureBufferOk};
  }, [truckBufferRules])

  const getLocationCountRuling = useCallback((event?: FTLEvent, location?: FTLLocation) => {
    if (!event || !location) {
      return { description: '', ok: true };
    }
    const bookedTruckCount = event.scheduledTrucks.length;
    const total = event.maxTrucks !== null ? event.maxTrucks : location.maxTrucks;
    let ok = true
    if (bookedTruckCount >= total) {
      ok = false;
    }
    return { description: `${bookedTruckCount} truck(s) out of ${total} for ${event.title}`, ok };
  }, [])

  return (
    <RulesContext.Provider value={{
      getRules,
      categoryCountRules,
      categoryCountRulesLocationOnly,
      categoryBufferRules,
      truckCountRules,
      truckBufferRules,
      getCategoryCountRuling,
      getCategoryBufferRuling,
      getTruckCountRuling,
      getTruckBufferRuling,
      getLocationCountRuling,
    }}>
      {props.children}
    </RulesContext.Provider>
  )
}

export default RulesContext;
